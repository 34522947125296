import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Avatar, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function RentalTableRow({
  row,
  selected,
  onEditRow,
  onViewRow,
  onDeleteRow,
  customerId,
}) {
  const {
    customer,
    vehicle,
    date_out,
    expecting_returning_date,
    status,
    rental_code,
    balance_amount,
    paid_amount,
  } = row;

  const confirm = useBoolean();

  const popover = usePopover();

  const firstLetter = customer?.name?.charAt(0).toUpperCase();

  const firstLetterVehicle = vehicle?.name?.charAt(0).toUpperCase();

  const handleStatus = (rentalStatus) => {
    switch (rentalStatus) {
      case 'active':
        return 'success';
      case 'returned':
        return 'info';
      case 'overdue':
        return 'error';
      case 'payment_pending':
        return 'warning';
      case 'payment_completed':
        return 'secondary';
      default:
        return 'success';
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{rental_code}</TableCell>
        {!customerId && (
          <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              alt={customer?.name}
              src={customer?.image && customer?.image?.length > 0 && customer?.image[0]?.url}
              sx={{ mr: 2 }}
            >
              {firstLetter}
            </Avatar>

            <ListItemText
              primary={customer?.name}
              secondary={customer?.primary_phone}
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
              }}
            />
          </TableCell>
        )}

        <TableCell>
          <Stack direction="row" alignItems="center" sx={{ width: 1 }}>
            <Avatar
              variant="rounded"
              alt={vehicle?.name}
              src={vehicle?.images && vehicle?.images?.length > 0 && vehicle?.images[0]?.url}
              sx={{ mr: 2 }}
            >
              {firstLetterVehicle}
            </Avatar>

            <ListItemText
              primary={vehicle?.name}
              secondary={vehicle?.registration_number}
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
              }}
            />
          </Stack>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDate(date_out)}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDate(expecting_returning_date)}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {/* {status} */}
          <Label
            variant="soft"
            color={handleStatus(status) || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {paid_amount?.toFixed(2)}
          {/* <Label
            variant="soft"
            color='success'
            sx={{ textTransform: 'capitalize' }}
          >
            {paid_amount}
          </Label> */}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label variant="soft" color="error" sx={{ textTransform: 'capitalize' }}>
            {balance_amount?.toFixed(2)}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color="default">
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>
        {/* {customerId && (
          <MenuItem
            onClick={() => {
              onEditRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )} */}

        <MenuItem
          onClick={() => {
            onDeleteRow();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

RentalTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  customerId: PropTypes.any,
};
